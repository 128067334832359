import React, { useRef, useState } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import Layout from "../components/base-structure/Layout";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../styles/Contact.scss";
import Seo from "../components/common/SEO";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "gatsby";

const content = [
  {
    id: 1,
    metaTitle: "Contact us - Invocom",
    metaDescription:
      "The fastest and easiest way to get in touch is by sending us a message here. You can also email us your query or reach out to social media pages.",
  },
];

export default function Contact() {
  const [captcha, isCaptcha] = useState(false);
  const captchaRef = useRef();

  const onChangeReCaptcha = (value) => {
    isCaptcha({ ...captcha, code: value, validation: true });
  };

  const initialValues = {
    FullName: "",
    Email: "",
    PhoneNumber: "",
    Message: "",
    acceptedTos: false,
  };

  const registrationSchema = Yup.object().shape({
    FullName: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("First name is required"),
    Email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Email is required"),
    PhoneNumber: Yup.string()
      .min(11, "Minimun 11 numbers Required")
      .max(20, "Number can't be more than 20")
      .required("Phone number is required"),
    Message: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Message is required"),
    acceptedTos: Yup.boolean().oneOf(
      [true],
      "Please accept the terms of services"
    ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: (values, action) => {
      axios
        .post(`https://st-backend-invocom.invo.zone/api/contact-uses`, {
          data: {
            FullName: values?.FullName,
            Email: values?.Email,
            PhoneNumber: values?.PhoneNumber,
            Message: values?.Message,
            recaptchaToken: captcha?.code,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            action.setStatus({ success: "Email sent !" });
            setTimeout(() => {
              captchaRef.current.reset();
              isCaptcha(false);
              action.resetForm();
            }, 1000);
            window.location = "/thank-you";
          } else {
          }
        })
        .catch((error) => {
          action.setStatus({ fail: "Email not sent !" });
        });
    },
  });

  return (
    <Layout>
      {content.map((contents) => (
        <div key={contents.id}>
          <Seo
            title={contents.metaTitle}
            description={contents.metaDescription}
            tag="index"
            pageUrl="contact-us/"
          />
        </div>
      ))}
      <div className="contactBG">
        <Box className="contactHead">
          <Typography variant="h1">Contact Us</Typography>
          <Box>
            <Box className="emailInfo">
              <EmailIcon sx={{ fill: "#611F69", fontSize: "22px" }} />
              <Typography variant="span">Email: info@invocom.io</Typography>
            </Box>
            <Box className="phoneInfo">
              <LocalPhoneIcon sx={{ fill: "#611F69", fontSize: "22px" }} />
              <Typography variant="span">Phone: +6011 2844 3855</Typography>
            </Box>
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <form>
                  <Box className="contactForm">
                    <input
                      type="text"
                      placeholder="Your Full Name"
                      {...formik.getFieldProps("FullName")}
                      // value={fullName}
                      // onChange={(e) => setFullName(e.target.value)}
                    />
                    {formik.touched.FullName && formik.errors.FullName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert" className="from-error">
                            {formik.errors.FullName}
                          </span>
                        </div>
                      </div>
                    )}
                    <Box className="phoneInput">
                      <PhoneInput
                        international
                        required
                        placeholder="Enter phone number"
                        value={formik.values.PhoneNumber}
                        onChange={(e) => formik.setFieldValue("PhoneNumber", e)}
                        onBlur={formik.handleBlur("PhoneNumber")}
                      />
                    </Box>
                    {formik.touched.PhoneNumber &&
                      formik.errors.PhoneNumber && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert" className="from-error">
                              {formik.errors.PhoneNumber}
                            </span>
                          </div>
                        </div>
                      )}
                    <input
                      type="email"
                      placeholder="E-mail Address"
                      {...formik.getFieldProps("Email")}
                      // onChange={(e) => setEmail(e.target.value)}
                      // value={email}
                    />
                    {formik.touched.Email && formik.errors.Email && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert" className="from-error">
                            {formik.errors.Email}
                          </span>
                        </div>
                      </div>
                    )}
                    <textarea
                      type="text"
                      placeholder="Message"
                      rows="4"
                      {...formik.getFieldProps("Message")}
                    />
                    {formik.touched.Message && formik.errors.Message && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span role="alert" className="from-error">
                            {formik.errors.Message}
                          </span>
                        </div>
                      </div>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        columnGap: "15px",
                      }}
                    >
                      <input
                        type="checkbox"
                        {...formik.getFieldProps("acceptedTos")}
                      />
                      <Typography
                        sx={{
                          margin: "0px",
                          color: "#303030",
                          fontFamily: "Poppins",
                        }}
                      >
                        I understand and agree to the{" "}
                        <Link to="/terms-and-conditions">
                          <Typography
                            variant="span"
                            sx={{
                              fontWeight: "500",
                              color: "#303030",
                              transition: "0.2s ease-in all",
                              "&:hover": {
                                textDecoration: "underline",
                              },
                            }}
                          >
                            terms & conditions.
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                    {formik.touched.acceptedTos &&
                      formik.errors.acceptedTos && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <span role="alert" className="from-error">
                              {formik.errors.acceptedTos}
                            </span>
                          </div>
                        </div>
                      )}
                    <Box>
                      <ReCAPTCHA
                        sitekey="6Le4fWYkAAAAACPFMudQ1IEuOcXRRAeguw6HzN__"
                        onChange={onChangeReCaptcha}
                        onExpired={() => isCaptcha(false)}
                        ref={captchaRef}
                      />
                    </Box>
                    <Button
                      onClick={formik.handleSubmit}
                      className="contactBtn"
                      disabled={formik.isSubmitting || !captcha.code}
                      type="submit"
                    >
                      SEND MESSAGE
                    </Button>
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          color: "green",
                          ":first-letter": {
                            textTransform: "capitalize",
                          },
                          fontWeight: "600",
                        }}
                      >
                        {/* {errors.email && touched.email ? errors.email : null} */}
                        <div>{formik.status ? formik.status.success : ""}</div>
                      </Typography>
                    </Box>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </Layout>
  );
}
